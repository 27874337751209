@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

p,ul,h1,h2,h3{
	margin: 0;
	line-height: 1.4;
}

p{
	font-size: 16px;
	@include tablet-up{
		font-size: 18px;
	}
}

.btn{
	display: inline-block;
	font-size: 18px;
	font-weight: 900;
	text-align: center;
	color: $black;
	background-color: transparent;
	border: solid 1px transparent;
	position: relative;
	&::before{
		content: "";
		display: block;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	span{
		display: block;
		position: relative;
		padding: 25px;
		transition: all .4s;
		min-height: 80px;
		min-width: 180px;
		@include flex(row,center,center);
	}
	&.btn-orange{
		&::before{
			background-image: url('../../dist/img/btn-blurb-orange.png');
		}
	}
	&.btn-yellow-s{
		&::before{
			background-image: url('../../dist/img/btn-blurb-yellow-s.png');
		}
		@include hover-focus{
			color: $pink;
		}
	}
	&.btn-yellow-l{
		&::before{
			background-image: url('../../dist/img/btn-blurb-yellow-l.png');
		}
		@include hover-focus{
			color: $pink;
		}
	}
	&.btn-blue{
		&::before{
			background-image: url('../../dist/img/btn-blurb-blue.png');
		}
		@include hover-focus{
			color: $pink;
		}
	}
	@include tablet-up{
		font-size: 20px;
		span{
			padding: 30px;
		}
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none;
	}
}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
		.side{
			flex: 1;
		}
	}
}

// header {}

main {
	overflow: hidden;
	section{
		position: relative;

		.flower{
			position: absolute;
			pointer-events: none;
		}

		.container{
			position: relative;
		}

		&#hero{
			padding: 40px 0 30px;
			background-color: $yellow;
			.container{
				text-align: center;
				color: $black;
				padding: 0 50px;
				img{
					margin: 0 auto;
					max-width: 100%;
					&.logo{
						margin-bottom: 40px;
						max-width: 120px;
					}
					&.copy{
						margin-bottom: 30px;
					}
				}
				p{
					// display: inline-block;
					strong{
						text-transform: uppercase; 
					}
					span{
						display: block;
						height: 5px;
						opacity: 0;
						pointer-events: none;
					}
					&:first-of-type{
						margin-bottom: 20px;
					}
					&:last-of-type{
						margin-bottom: 20px;
					}
				}
				.btn{
					display: block;
					max-width: max-content;
					margin: 0 auto;
				}
			}

			.flower{
				&.flower-03{
					width: 200px;
					height: 200px;
					top: -70px;
					left: -80px;
				}
				&.flower-02{
					width: 60px;
					height: 60px;
					top: calc(50% - 30px);
					left: -10px;
				}
				&.flower-01{
					width: 90px;
					height: 90px;
					bottom: 30px;
					left: -30px;
				}
				&.flower-04{
					width: 140px;
					height: 140px;
					right: 10px;
					top: -100px;
				}
				&.flower-05{
					width: 90px;
					height: 90px;
					right: -45px;
					top: 60px;
				}
				&.flower-06{
					width: 70px;
					height: 70px;
					top: calc(50%);
					right: -20px;
				}
				&.flower-07{
					width: 60px;
					height: 60px;
					bottom: -10px;
					right: -30px;
				}
			}
			
			@include tablet-up{
				.container{
					img{
						&.logo{
							max-width: 140px;
							margin-bottom: 30px;
						}
						&.copy{
							max-height: 60vh;
						}
					}
					p{
						&:first-of-type{
							margin-bottom: 10px;
						}
						span{
							padding: 0 20px;
							display: inline;
							height: unset;
							pointer-events: all;
							opacity: 1;
						}
					}
				}
				.flower{
					&.flower-03{
						width: 300px;
						height: 300px;
						top: -40px;
						left: -10px;
					}
					&.flower-02{
						width: 100px;
						height: 100px;
						left: 60px;
					}
					&.flower-01{
						width: 120px;
						height: 120px;
						left: -60px;
					}
					&.flower-04{
						width: 260px;
						height: 260px;
						right: 240px;
						top: -40px;
					}
					&.flower-05{
						width: 180px;
						height: 180px;
						right: -20px;
						top: 200px;
					}
					&.flower-06{
						width: 120px;
						height: 120px;
						top: calc(65%);
						right: 180px;
					}
					&.flower-07{
						display: none;
					}
				}
			}
		}

		&#about{
			background-color: $blue;
			color: $black;
			padding: 60px 0;

			.container{
				padding:  0 50px;
				text-align: center;
				.split{
					.side{
						p{
							margin-bottom: 20px;
						}
						img{
							width: 100%;
							display: inline-block;
							margin-bottom: 20px;
						}
						>img{
							max-width: 300px; 
						}
						.wrapper{
							img{
								max-width: 400px;
							}
						}
						.buttons{
							@include flex(row,center,center);
							gap: 10px;
						}
					}
				}
			}

			.flower{
				&.flower-01{
					opacity: 0;
				}
				&.flower-02{
					width: 120px;
					height: 120px;
					right: -70px;
					top: 35%;
				}
				&.flower-03{
					width: 80px;
					height: 80px;
					bottom: 30px;
					left: -40px;
				}
				&.flower-04{
					width: 120px;
					height: 120px;
					bottom: 20px;
					right: -70px;
				}
			}

			@include tablet-up{
				.container{
					.split{
						align-items: center;
						gap: 60px;
						.side{
							text-align: left;
							.wrapper{
								text-align: center;
								img{
									max-width: 100%;
								}
							}
							.buttons{
								justify-content: flex-start;
							}
						}
					}
				}
				.flower{
					&.flower-01{
						opacity: 1;
						width: 150px;
						height: 150px;
						right: -50px;
						top: -20px;
					}
					&.flower-02{
						width: 240px;
						height: 240px;
						right: -140px;
						top: 45%;
					}
					&.flower-03,
					&.flower-04{
						opacity: 0;
					}
				}
			}
		}

		&#date{
			padding: 60px 0;
			background-color: $pink;
			color: $black;
			z-index: 99;

			.cta{
				margin-top: .2em;
				span{
					display: inline-block;
				}
			}

			.props{
				position: absolute;
				height: calc(100% - 120px);
				object-fit: contain;
				top: 60px;
				&.props-left{
					left: -40px;
				}
				&.props-right{
					right: -40px;
				}
			}

			.container{
				padding: 0 70px;
				text-align: center;
				.split{
					.side{
						img{
							width: 100%;
							display: inline-block;
							max-width: 250px;
							margin-bottom: 20px;
						}
						#countdown{
							padding: 10px 0;
							span{
								font-size: 60px;
								font-weight: 900;
								color: $yellow;
								-webkit-text-stroke: 2px $black;
								position: relative;
								&.hours,&.minutes,&.seconds{
									&::after{
										content: "TEST";
										text-transform: uppercase;
										position: absolute;
										width: 100%;
										font-size: 16px;
										text-align: center;
										left: 0;
										bottom: -5px;
										-webkit-text-stroke: 1px $black;
									}
								}
								&.hours{
									&::after{
										content: "hours";
									}
								}
								&.minutes{
									&::after{
										content: "mins";
									}
								}
								&.seconds{
									&::after{
										content: "secs";
									}
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				.props{
					display: none;
				}
				.flower{
					height: 250px;
					top: calc(50% - 125px);
					left: -125px;
				}
				.container{
					max-width: 100%;
					.split{
						align-items: center;
						min-height: 600px;
						.side{
							.wrapper{
								padding-top: 100px;
								max-width: 615px;
								margin-left: auto;
								img{
									max-width: 400px;
								}
								p{
									max-width: 420px;
									margin: .2em auto 0;
								}
								#countdown{
									margin-bottom: 10px;
									span{
										font-size: 80px;
									}
								}
							}
							&:last-child{
								padding-left: 40px;
								img{
									width: 100%;
									max-width: 100%;
									height: calc(100% + 200px);
									position: absolute;
									width: 50%;
									top: -100px;
									right: -100px;
									object-fit: contain;
									object-position: left;
								}
							}
						}
					}
				}
			}

		}

		&#footer{
			padding: 60px 0 20px;
			background-color: $orange;
			color: $black;
			z-index: 9;
			.flower{
				&.flower-01{
					width: 150px;
					left: -70px;
					top: 60px;
				}
				&.flower-02{
					right: 0;
					width: 100px;
					bottom: 80px;
				}
				&.mic{
					width: 100px;
					bottom: -10px;
					left: 10px;
					transform: rotate(-10deg);
				}
			}
			.container{
				padding: 0 50px;
				text-align: center;
				h4{
					margin: 0;
					font-size: 22px;
				}
				img{
					display: block;
					margin: 40px auto;
					max-width: 200px
				}
				.social{
					@include flex(row,center,center);
					gap: 10px;
					a{
						font-size: 20px;
						color: $black;
						@include hover-focus{
							color: $pink;
						}
					}
				}
			}

			@include tablet-up{
				padding: 100px 0 20px;
				.flower{
					&.flower-01{
						width: 200px;
						top: calc(50% - 100px);
						left: 150px;
					}
					&.flower-02{
						width: 150px;
						top: calc(65% - 100px);
						right: 200px;
					}
					&.mic{
						width: 150px;
						left: calc(50% - 300px);
						bottom: -5px;
					}
				}
				.container{
					h4{
						font-size: 32px;
						margin-bottom: 20px;
					}
					img{
						max-width: 300px;
						margin-bottom: 100px;
					}
					.social{
						gap: 15px;
						a{
							font-size: 30px;
						}
					}
				}
			}
		}

	}

}

footer {

}